<template>
    <div>
        <div class="columns is-gapless" style="height: 100%; margin-top: 0; margin-bottom: 0;">
            <div class="column is-4" style="overflow: auto; margin-top: 10px;">

                <div
                    class="auth">
                    <b-button
                        type="is-info"
                        @click="login()"
                        v-if="!isAuth"
                    >
                            Se connecter
                    </b-button>
                    <!--
                    <b-button
                        type="is-success"
                        @click="checkJWT()"
                        v-if="whoiam().auth"
                    >
                        Check JWT
                    </b-button>
                    -->
                    <b-button
                        type="is-warning"
                        @click="logout()"
                        v-if="isAuth">
                        Se déconnecter du compte de {{ whoiam().name }}
                    </b-button>
                </div>


                <b-tabs type="is-boxed">
                    <b-tab-item label="Accueil" icon="note">
                        <section>
                            <div class="container">
                                <div class="title">Accueil</div>
                                <hr>
                                <div class="columns">
                                    <div class="column is-one-quarter">
                                        <img
                                            :src="require('@/assets/logo_natura2000.jpg')"
                                            alt="Logo CEN NA"
                                            width="150px"
                                        >
                                    </div>
                                    <div class="column is-one-quarter">
                                        <img
                                            :src="require('@/assets/logo_oreka_def.jpg')"
                                            alt="Logo OREKA MENDIAN"
                                            width="150px"
                                        >
                                    </div>
                                    <div class="column is-one-quarter">
                                        <img
                                            :src="require('@/assets/logo_life.jpg')"
                                            alt="Logo LIFE"
                                            width="150px"
                                        >
                                    </div>
                                    <div class="column is-one-quarter">
                                        <img
                                            :src="require('@/assets/picto_transpa.svg')"
                                            alt="Logo CEN NA"
                                            width="150px"
                                        >
                                    </div>
                                </div>
                                <hr>
                                <div class="subtitle">Outil d’aide à la décision pour la gestion
                                    des milieux agropastoraux des sites Natura 2000 en Iparralde (Pays Basque Nord)</div>
                                <p>Cet outil a été imaginé, conçu et élaboré par le
                                    <a href="https://cen-nouvelle-aquitaine.org/" target="_blank">
                                    Conservatoire d’Espaces Naturels de Nouvelle-Aquitaine
                                </a>
                                    dans le cadre du programme <a href="http://www.lifeorekamendian.eu/" target="_blank">LIFE Oreka Mendian (2016-2022)</a>, piloté
                                    par la Fondation Hazi, et associant des partenaires du pays Basque sud et nord pour
                                    la préservation des milieux agropastoraux au sein du réseau de sites Natura 2000 de
                                    la montagne basque.
                                </p>
                                <p>A travers une entrée cartographique, il permet de consulter de façon croisée les
                                    informations disponibles de nature à éclairer la gestion des espaces pastoraux des
                                    sites Natura 2000 de la montagne basque, en tenant notamment compte des enjeux
                                    relatifs à la biodiversité (habitats naturels, faune, flore), et à la gestion
                                    pastorale.
                                </p>
                                <p>Et outil est concçu de façon à pouvoir intégrer, au fure et à mesure de leur
                                    acquisition, de nouvelles données, ou des données mises à jour, par le CEN
                                    Nouvelle Aquitaine et ses partenaires, ou par les différents usagers de cet outil.
                                </p>
                            </div>
                        </section>
                    </b-tab-item>
                    <b-tab-item label="Sélection" icon="note">
                        <CartoucheGauche
                            :dataFromDB="dataFromDB"
                            :highlight="highlight"
                            @displayLayers="displayLayers($event)"
                            @fullReport="fullReport($event)"
                            @ViewAddCommentaires="ViewAddCommentaires($event)"
                        ></CartoucheGauche>
                    </b-tab-item>
                    <b-tab-item label="Couches" icon="note">
                        <LayersManager
                            :mapLayers="mapLayers"
                            :themes="legendes.themes"
                            :filteredTags="legendes.themes"
                            :isAuth="isAuth"
                        />
                    </b-tab-item>
                    <b-tab-item label="CGU" icon="note">
                        <section>
                            <div class="container">
                                <div class="title">CGU</div>
                                <p>A renseigner.</p>
                            </div>
                        </section>
                    </b-tab-item>
                </b-tabs>

            </div>

            <div class="column is-8" style="height:99vh; position: sticky; top: 0rem;">
                <MainMap
                    @loadCard="loadCard($event)"
                    @clearCard="clearCard"
                    @PDFproduction="PDFproduction($event)"
                    @highlightSelectMozaic="highlightSelectMozaic($event)"
                    :layersToDisplay="layers"
                    :mapLayers="mapLayers"
                >
                </MainMap>
            </div>
        </div>

        <!-- MODALs -->
        <b-modal
            v-model="fullReportModal"
            has-modal-card
            full-screen
            :can-cancel="false">
            <full-report
                :selectedMozaic="selectedMozaic"
                :legendes="legendes"
                :Img="Img"
            >
            </full-report>
        </b-modal>
        <b-modal
            v-model="commentairesModal"
            has-modal-card
            full-screen
            :can-cancel="false">
            <ModalCommentaires
                :mozUUID="mozUUID"
                :themes="legendes.themes">
            </ModalCommentaires>
        </b-modal>

    </div>
</template>

<script>

import CartoucheGauche from "./CartoucheGauche";
import MainMap from "./MainMap";

import domtoimage from 'dom-to-image';
import FullReport from "./fullReport";
import ModalCommentaires from "./ModalCommentaires";
import LayersManager from "@/components/LayersManager";

export default {
    name: 'HelloWorld',
    components: {
        LayersManager,
        FullReport,
        ModalCommentaires,
        MainMap,
        CartoucheGauche},
    mounted() {
        this.$axios.get('legendes').then(r => {
            this.legendes = r.data
        })
        // Wait 2 sec and read the localstorage
        setTimeout(()=>{
            this.isAuth = this.whoiam().auth
        }, 2000);

    },
    methods: {
        async checkJWT() {
            let t = localStorage.getItem('vue-token');
            console.log(t)
            let r = await this.$axiosAuth.get(`checkJWT`)
            console.log(r.data)
        },
        highlightSelectMozaic(e){
            this.highlight = e;
        },
        displayLayers(e) {
            this.layers = e
        },
        loadCard(e) {
            this.dataFromDB = e;
        },
        clearCard() {
            this.dataFromDB = {
                dataFromDB: null,
                selection: null
            };
        },
        fullReport(e) {
            this.selectedMozaic.dataFromDB = this.dataFromDB.dataFromDB.filter(i => i.color === e)
            this.selectedMozaic.selection = this.dataFromDB.selection
            this.selectedMozaic.observations = this.dataFromDB.observations
            this.selectedMozaic.habitats_pref = this.dataFromDB.habitats_pref
            let node = document.querySelector('#mainmap');
            domtoimage.toPng(node,
                {}).then(dataURL => {
                this.Img = dataURL;
                this.fullReportModal = true
            })

        },
        ViewAddCommentaires(e) {
            this.mozUUID = e.habitats[0].id_sta_sin
            this.commentairesModal = true
        },
        PDFproduction(e) {

            // Screenshot
            console.log(e)

            let node = document.querySelector('#mainmap');
            domtoimage.toPng(node,
                {})
                .then(function (dataUrl) {

                    // Post image to backend for process
                    this.$axios.post('pdf', {
                        img: dataUrl,
                        dataFromDB: this.dataFromDB
                    }, {
                        responseType: 'arraybuffer',
                        headers: {
                            'Accept': 'application/pdf'
                        }

                    })
                        .then(function (response) {
                            const url = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', `résultats.pdf`); //or any other extension
                            document.body.appendChild(link);
                            link.click();
                        }.bind(this))
                        .catch(error => console.log(error));

                }.bind(this))
                .catch(function (error) {
                    console.error('oops, something went wrong!', error);
                });

        }
    },
    data() {
        return {
            isAuth: false,
            mozUUID: null,
            highlight: '',
            selectedMozaic: {},
            layers: {},
            legendes: {},
            dataFromDB: {
                dataFromDB: null,
                selection: null
            },
            fullReportModal: false,
            commentairesModal: false,
            Img: "",
            mapLayers: {
                sols: false,
                habitats: true,
                placettes: false,
                placettes_year: '%',
                ecobuage: false,
                ecobuage_year: '2017-2018',
                obstecoul: false,
                sig_convention: [],
                commentaires: []
            },
        }
    }


}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.column {
    padding-bottom: 0px;
}

.container {
    max-width: 100%;
    padding-top: 2%;
    padding-left: 5%;
    padding-right: 5%;
}

li {
    margin-top: 20px;
}

.auth {
    margin-left: 10px;
    margin-bottom: 10px;
}

</style>
