<template>

    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">Description de la zone sélectionnée</p>
        </header>
        <section class="modal-card-body">

            <!-- CONTENT -->

            <div class="columns">
                <div class="column is-one-third">
                    <div class="crop">
                        <img :src="Img" alt="Vignette">
                    </div>
                </div>
                <div class="column is-one-third">
                    <p class="subtitle">Caractéristiques de l'emprise sélectionnée</p>
                    <b>Surface sélectionnée</b> : {{ contenanceToSurface(selectedMozaic.selection.area) }}
                    <br>
                    <b>Commune</b> : {{ selectedMozaic.selection.commune }}
                    <br>
                    <!-- TODO -- <b>Sol</b> : {{ selectedMozaic.sols }} -->
                    <br>
                    <br>
                    <p class="subtitle">Synthèse des habitats dans la mosaïque sélectionnée</p>
                    <!-- Check if more than 100% -->
                    <b-message
                        v-if="calcul100() > 100"
                        title="Attention"
                        type="is-warning"
                        has-icon
                        aria-close-label="Fermer le message">
                        Attention, plus de 100% de recouvrement ({{ calcul100() }}%), le polygone contient donc des
                        superpositions d'habitats.
                    </b-message>
                    <b-message
                        v-if="calcul100() === 100"
                        title="Attention"
                        type="is-info"
                        has-icon
                        aria-close-label="Fermer le message">
                        La somme du recouvrement des habitats est égale à 100%, il n'y a donc pas de superpositions
                        d'habitats.
                    </b-message>

                    <!-- Synthèse de navigation rapide -->

                    <table class="synthese">
                        <th style="padding: 3px;">% d'occupation</th>
                        <th style="padding: 3px;">Code EUNIS</th>
                        <th style="padding: 3px;">Légende</th>
                        <th style="padding: 3px;">Voir</th>

                        <tr
                            v-for="h in selectedMozaic.dataFromDB[0].habitats"
                            :key="h.gid"
                        >
                            <td style="padding: 3px;">
                                {{ h.surf_prcen }} %
                            </td>
                            <td style="padding: 3px;">
                                {{ h.code_eunis }}
                            </td>
                            <td style="padding: 3px;">
                                <span v-html="h.legende"></span>
                            </td>
                            <td style="padding: 3px;">
                                <a
                                    :href="'#' + h.gid"
                                >
                                    <b-button type="is-info"
                                              size="is-small"
                                              icon-right="arrow-down"/>
                                </a>
                            </td>

                        </tr>
                    </table>

                </div>
                <div class="column is-one-third">
                    <p class="subtitle">Observations d'espèces à forts enjeux</p>

                    <span
                        v-if="selectedMozaic.observations.length > 0"
                    >
                        <em>Sur la zone sélectionnée, avec une zone tampon d'un diamètre de 500m.</em>
                    <br>
                    <br>
                    <b-table
                        :data="selectedMozaic.observations"
                        bordered
                        striped
                        narrowed>

                        <b-table-column field="nom_francais" label="Nom français" width="80"
                                        v-slot="props">
                            {{ props.row.nom_francais }}
                        </b-table-column>

                        <b-table-column field="date_obs" label="date_obs" width="40"
                                        v-slot="props">
                            {{ new Date(props.row.date_obs).getFullYear() }}
                        </b-table-column>

                        <b-table-column field="observateur" label="observateur" width="80"
                                        v-slot="props">
                            {{ props.row.observateur }}
                        </b-table-column>

                        <b-table-column field="organisme" label="organisme" width="80"
                                        v-slot="props">
                            {{ props.row.organisme }}
                        </b-table-column>

                    </b-table>
                    </span>
                    <span
                        v-if="selectedMozaic.observations.length === 0"
                    >
                        Pas encore d'observations dans cette zone.
                    </span>

                </div>
            </div>


            <hr class="dotted">

            <b-collapse
                class="card"
                animation="slide"
                v-for="(items, index) of selectedMozaic.dataFromDB"
                :key="index"
                :open="isOpen === index"
                @open="isOpen = index"
                :aria-id="'contentIdForA11y5-' + index">
                <template #trigger="props">
                    <div
                        class="card-header"
                        role="button"
                        v-bind:style="{ 'background-color': items.color}"
                        :aria-controls="'contentIdForA11y5-' + index"
                        :aria-expanded="props.open"
                    >
                        <p class="card-header-title">
                            Cliquer pour dérouler
                        </p>
                        <a class="card-header-icon">
                            <b-icon
                                :icon="props.open ? 'menu-down' : 'menu-up'">
                            </b-icon>
                        </a>
                    </div>
                </template>
                <div class="card-content">
                    <div class="content">

                        <div v-for="item in items.habitats" :key="item.code_eunis">

                            <div class="card-content">

                                <div
                                    class="subtitle"
                                    :id="item.gid"> {{ item.code_eunis }} : <span v-html="item.legende"></span>
                                    ({{ item.surf_prcen }} %)
                                </div>

                                <!-- Subcard -->
                                <div class="card">
                                    <header
                                        class="card-header"
                                        style="background-color: #e5e5e5">
                                        <p class="card-header-title">
                                            Fiche technique
                                        </p>
                                    </header>
                                    <div class="card-content">

                                        <!-- Fiche technique -->
                                        <a
                                            v-if="item.cbnpmp_link"
                                            :href="item.cbnpmp_link"
                                            target="_blank"
                                        >
                                            Voir la fiche habitat sur le site du Conservatoire botanique national
                                            des Pyrénées et de Midi-Pyrénées
                                        </a>

                                        <br>
                                    </div>
                                </div>
                                <br>

                                <div class="columns">

                                    <!-- Column 1 -->
                                    <div class="column">
                                        <!-- Subcard -->
                                        <div class="card">
                                            <header
                                                class="card-header"
                                                style="background-color: #e5e5e5">
                                                <p class="card-header-title">
                                                    Influence des facteurs naturels : sensibilité à la colonisation
                                                </p>
                                            </header>
                                            <div class="card-content">
                                                <!-- COLONISATION -->
                                                <div class="columns">
                                                    <div class="column is-three-fifths">
                                                        <table>
                                                            <tr>
                                                                <!-- <th>Espèces de ZH</th> -->
                                                                <th colspan="2">
                                                                    <img
                                                                        :src="require('@/assets/picto_fougere_aigle.png')"
                                                                        alt="resto"
                                                                        width="50px"
                                                                    ><br>
                                                                    Fougère-aigle et Brachypode
                                                                </th>

                                                            </tr>
                                                            <tr>
                                                                <!--
                                                                <th>
                                                                    <img
                                                                        :src="require('@/assets/juncus.jpg')"
                                                                        alt="Espèce zones humides"
                                                                        height="200px"
                                                                    >
                                                                </th>
                                                                -->
                                                                <th>
                                                                    <img
                                                                        :src="require('@/assets/fougere-aigle.jpg')"
                                                                        alt="Fougère Aigle"
                                                                        height="200px"
                                                                    >
                                                                </th>
                                                                <th>
                                                                    <img
                                                                        :src="require('@/assets/brachypode.jpg')"
                                                                        alt="Brachypode"
                                                                        height="200px"
                                                                    >
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <!--
                                                                <td v-bind:style="{ backgroundColor: cell_color(item['sensibilite_colonisation_zh']) }">
                                                                    {{ link_to_label(item['sensibilite_colonisation_zh']) }}
                                                                </td>
                                                                -->
                                                                <td v-bind:style="{ backgroundColor: cell_color(item['sensibilite_colonisation_fougereaigle']) }">
                                                                    {{
                                                                        link_to_label(item['sensibilite_colonisation_fougereaigle'])
                                                                    }}
                                                                </td>
                                                                <td v-bind:style="{ backgroundColor: cell_color(item['sensibilite_colonisation_brachypode']) }">
                                                                    {{
                                                                        link_to_label(item['sensibilite_colonisation_brachypode'])
                                                                    }}
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <div class="column">

                                                        <table>
                                                            <tr>
                                                                <th>
                                                                    <img
                                                                        :src="require('@/assets/picto_ligneux.png')"
                                                                        alt="resto"
                                                                        width="50px"
                                                                    ><br>
                                                                    Ligneux
                                                                </th>


                                                            </tr>
                                                            <tr>
                                                                <th>
                                                                    <img
                                                                        :src="require('@/assets/ligneux.jpeg')"
                                                                        alt="Ligneux"
                                                                    >
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <td v-bind:style="{ backgroundColor: cell_color(item['sensibilite_colonisation_ligneux']) }">
                                                                    {{
                                                                        link_to_label(item['sensibilite_colonisation_ligneux'])
                                                                    }}
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <!-- Column 2 -->
                                    <div class="column">
                                        <!-- Subcard -->
                                        <div class="card">
                                            <header
                                                class="card-header"
                                                style="background-color: #e5e5e5">
                                                <p class="card-header-title">
                                                    Influence des facteurs liés à la gestion pastorale
                                                </p>
                                            </header>
                                            <div class="card-content">

                                                <!-- FEU -->
                                                <table>
                                                    <tr>
                                                        <th></th>
                                                        <th>Résilience au feu</th>
                                                        <th>Sensibilité au feu</th>
                                                        <th>Fréquence préconisée</th>
                                                    </tr>
                                                    <tr>
                                                        <td>

                                                            <img
                                                                :src="require('@/assets/picto_feu.png')"
                                                                alt="feu"
                                                                width="50px"
                                                            >
                                                        </td>
                                                        <td v-bind:style="{ backgroundColor: cell_color_revert(item['resilience_au_feu']) }">
                                                            <b-tooltip :label="item.desc"
                                                                       position="is-right">
                                                                {{
                                                                    link_to_label_resilience(item['resilience_au_feu'])
                                                                }}
                                                            </b-tooltip>
                                                        </td>
                                                        <td v-bind:style="{ backgroundColor: cell_color(item['sensibilite_au_feu']) }">
                                                            {{ link_to_label(item['sensibilite_au_feu']) }}
                                                        </td>
                                                        <td v-bind:style="{ backgroundColor: cell_color(item['frequence_feu_preconisee']) }">
                                                            {{ link_to_label(item['frequence_feu_preconisee']) }}
                                                        </td>
                                                    </tr>
                                                </table>

                                                <!-- ABROUTISSEMENT -->
                                                <table>
                                                    <tr>
                                                        <th></th>
                                                        <th>Favorabilité à l'abroutissement (paturage)</th>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <img
                                                            :src="require('@/assets/picto_abroutissement.png')"
                                                            alt="feu"
                                                            width="50px"
                                                        >
                                                        </td>
                                                        <td v-bind:style="{ backgroundColor: cell_color_revert(item['favorabilite_abroutissement']) }">
                                                            {{ link_to_label(item['favorabilite_abroutissement']) }}
                                                        </td>
                                                    </tr>
                                                </table>

                                                <!-- PIETINEMENT -->
                                                <table>
                                                    <tr>
                                                        <th></th>
                                                        <th>Sensibilité piétinement</th>
                                                        <th>Favorabilité piétinement</th>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <img
                                                                :src="require('@/assets/picto_pietinement.png')"
                                                                alt="feu"
                                                                width="50px"
                                                            >
                                                        </td>
                                                        <td v-bind:style="{ backgroundColor: cell_color(item['sensibilite_pietinement']) }">
                                                            {{ link_to_label(item['sensibilite_pietinement']) }}
                                                        </td>
                                                        <td v-bind:style="{ backgroundColor: cell_color_revert(item['favorabilite_pietinement']) }">
                                                            {{ link_to_label(item['favorabilite_pietinement']) }}
                                                        </td>
                                                    </tr>
                                                </table>

                                                <b>Modalités de pâturage le plus favorable</b> :
                                                {{ item.type_paturage_favorable }}


                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Subcard -->
                                <div class="card">
                                    <header
                                        class="card-header"
                                        style="background-color: #e5e5e5">
                                        <p class="card-header-title">
                                            Quelques espèces animales potentiellement présentes
                                        </p>
                                    </header>
                                    <div class="card-content">
                                        <!-- TO DO -->

                                        <b-table
                                            :data="hab_pref(item.code_eunis)"
                                        >

                                            <b-table-column field="nom_francais" label="Nom français" v-slot="props">

                                                <b-button
                                                    label="Voir la fiche"
                                                    type="is-primary"
                                                    size="is-small"
                                                    @click="getAPI_INPN(props.row.cdnom)"/>

                                                <a :href="`https://inpn.mnhn.fr/espece/cd_nom/${props.row.cdnom}`"
                                                   target="_blank">
                                                    {{ props.row.nom_francais }} ({{ props.row.type }})
                                                </a>
                                            </b-table-column>

                                            <b-table-column field="status" label="Statuts"
                                                            v-slot="props">
                                                <!-- STATUS -->
                                                <b-taglist
                                                    v-if="props.row.status">
                                                    <!-- Liste rouge -->
                                                    <b-tag
                                                        type="is-info"
                                                        v-if="props.row.status.localRedList"
                                                    >
                                                        <b-tooltip :label="props.row.status.localRedList"
                                                                   position="is-top">
                                                            LR
                                                        </b-tooltip>
                                                    </b-tag>

                                                    <!-- Protection nationnale -->
                                                    <b-tag
                                                        type="is-warning"
                                                        v-if="props.row.status.nationalProtection">
                                                        <b-tooltip :label="props.row.status.nationalProtection"
                                                                   position="is-top">
                                                            PN
                                                        </b-tooltip>
                                                    </b-tag>

                                                </b-taglist>

                                            </b-table-column>

                                            <b-table-column field="habitats_utilises" label="Habitats utilisés"
                                                            v-slot="props">
                                                {{ props.row.habitats_utilises }}
                                            </b-table-column>

                                            <b-table-column field="condition" label="Condition" v-slot="props">
                                                {{ props.row.condition }}
                                            </b-table-column>

                                            <b-table-column field="eunis_cible" label="Habitat cible INPN"
                                                            v-slot="props">
                                                {{ props.row.code_habitat }}
                                            </b-table-column>

                                            <b-table-column field="sensibilite_feu" label="Sensibilité feu"
                                                            v-slot="props">
                                                <b-tooltip :label="props.row.precision_effet_feu"
                                                           position="is-top">
                                                    {{ props.row.sensibilite_feu }}
                                                </b-tooltip>

                                            </b-table-column>

                                        </b-table>

                                    </div>
                                </div>
                                <br>


                                <!-- Subcard -->
                                <div class="card">
                                    <header
                                        class="card-header"
                                        style="background-color: #e5e5e5">
                                        <p class="card-header-title">
                                            Autres conseils de gestion
                                        </p>
                                    </header>
                                    <div class="card-content">
                                        <img
                                            :src="require('@/assets/picto_conseil.png')"
                                            alt="resto"
                                            width="50px"
                                        >
                                        {{ item.conseil_gestion_conservation ? item.conseil_gestion_conservation : 'Manque d\'information à propos des conseils de gestion pour conservation' }}
                                        <br>
                                        <img
                                            :src="require('@/assets/picto_restauration.png')"
                                            alt="resto"
                                            width="50px"
                                        >
                                        {{ item.restauration ? item.restauration : 'Manque de données sur la restauration' }}
                                        <br>
                                        <img
                                            :src="require('@/assets/picto_apiculture.png')"
                                            alt="miel"
                                            width="50px"
                                        >
                                        {{ item['valeur_apicole'] ? item['valeur_apicole'] : 'Manque de données sur la valeur apicole' }}

                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </b-collapse>

            <!-- Modal -->
            <b-modal v-model="isCardModalActive" scroll="keep">
                <div class="card">
                    <div class="card-content">

                        <p class="title is-4">{{ INPN.description.frenchVernacularName }}</p>
                        <p class="subtitle is-6"><span v-html="INPN.description.fullNameHtml"></span></p>

                        <div class="columns">
                            <div class="column">
                                <img
                                    :src="INPN.media"
                                    alt="Pas d'image disponible"
                                    width="100%"
                                >
                            </div>
                            <div class="column">
                                <h2 class="subtitle is-4">Informations</h2>
                                <ul>
                                    <li>
                                        - Relation habitat : {{ precoTaxon.relation_habitat }}
                                    </li>
                                    <li>
                                        - Condition : {{ precoTaxon.condition }}
                                    </li>
                                    <li>
                                        - Embroussaillement : {{ precoTaxon.embroussaillement }}
                                    </li>
                                    <li>
                                        - Favorabilité broyage : {{ precoTaxon.favorabilite_broyage }}
                                    </li>
                                    <li>
                                        - Gestion : {{ precoTaxon.gestion }}
                                    </li>
                                    <li>
                                        - Habitats utilisés : {{ precoTaxon.habitats_utilises }}
                                    </li>
                                    <li>
                                        - Menaces : {{ precoTaxon.menaces }}
                                    </li>
                                    <li>
                                        - Sensibilité au feu : {{ precoTaxon.sensibilite_feu }}
                                    </li>
                                    <li>
                                        - Sensibilite piétinement surpaturage :
                                        {{ precoTaxon.sensibilite_pietinement_surpaturage }}
                                    </li>
                                </ul>


                            </div>
                        </div>


                        <div class="media">
                            <div class="media-content">
                                <!-- Fiche descriptive -->
                                <b-collapse
                                    v-if="INPN.fiche !== ''"
                                    aria-id="contentIdForA11y2"
                                    class="panel"
                                    animation="slide"
                                    v-model="ficheIsOpen">
                                    <template #trigger>
                                        <div
                                            class="panel-heading"
                                            role="button"
                                            aria-controls="contentIdForA11y2"
                                            :aria-expanded="ficheIsOpen">
                                            <strong>Description de l'espèce</strong>
                                        </div>
                                    </template>
                                    <span v-html="INPN.fiche"></span>
                                </b-collapse>
                                <p
                                    v-if="INPN.fiche === ''">
                                    <em>Pas de description sur le site de l'INPN</em>
                                </p>
                            </div>
                        </div>

                        <div class="content">
                            <small>Photo et description : <a href="https://inpn.mnhn.fr"
                                                             target="_blank">inpn.mnhn.fr</a> </small>
                        </div>
                    </div>
                </div>
            </b-modal>

        </section>


        <footer class="modal-card-foot">
            <b-button
                label="Fermer l'analyse"
                @click="$parent.close()"/>
            <!-- LEGENDE -->
            <p>Légende des couleurs : </p> &nbsp;
            <table>
                <tr>
                    <td v-bind:style="{ backgroundColor: cell_color(1) }" style="padding: 3px;">
                        Très positif
                    </td>
                    <td v-bind:style="{ backgroundColor: cell_color(2) }" style="padding: 3px;">
                        Positif
                    </td>
                    <td v-bind:style="{ backgroundColor: cell_color(3) }" style="padding: 3px;">
                        Moyen
                    </td>
                    <td v-bind:style="{ backgroundColor: cell_color(4) }" style="padding: 3px;">
                        Négatif
                    </td>
                    <td v-bind:style="{ backgroundColor: cell_color(5) }" style="padding: 3px;">
                        Très négatif
                    </td>
                </tr>
            </table>
        </footer>
    </div>

</template>

<script>

export default {
    name: "fullReport",
    props: {
        selectedMozaic: {},
        legendes: {},
        Img: {},
    },
    mounted() {
        console.log(this.selectedMozaic)
    },
    methods: {
        calcul100() {
            return this.selectedMozaic.dataFromDB[0].habitats.reduce((accumulator, object) => {
                return accumulator + parseFloat(object.surf_prcen);
            }, 0);
        },
        getAPI_INPN: async function (cdnom) {
            this.INPN = {
                description: {},
                media: {},
                fiche: ''
            };
            try {
                const description = await this.$axios.get(`https://taxref.mnhn.fr/api/taxa/${cdnom}`)
                this.INPN.description = description.data
            } catch (err) {
                // console.log(err)
            }
            try {
                const media = await this.$axios.get(`https://taxref.mnhn.fr/api/taxa/${cdnom}/media`)
                this.INPN.media = media.data._embedded.media[0]._links.thumbnailFile.href
            } catch (err) {
                // console.log(err)
            }
            try {
                const fiche = await this.$axios.get(`https://taxref.mnhn.fr/api/taxa/${cdnom}/factsheet`)
                this.INPN.fiche = fiche.data.text
            } catch (err) {
                // console.log(err)
            }
            // Get database info
            try {
                const fiche = await this.$axios.get(`taxon/${cdnom}`)
                this.INPN.fiche = fiche.data.text
            } catch (err) {
                // console.log(err)
            }
            this.precoTaxon = this.selectedMozaic.habitats_pref.find(t => (t.cdnom === cdnom))
            // console.log(this.precoTaxon)
            this.isCardModalActive = true
        },
        hab_pref: function (eunis) {
            return this.selectedMozaic.habitats_pref.filter(hab => hab.original_code === eunis);
        },
        link_to_label: function (note = null) {
            const r = this.legendes.sensibilite_generique.filter(label => label.id === parseInt(note))
            if (r.length === 0) {
                return 'Manque de données'
            } else {
                return r[0].lib
            }
        },
        link_to_label_resilience: function (note = null) {
            const r = this.legendes.resilience_feu.filter(label => label.id === parseInt(note))
            if (r.length === 0) {
                return 'Manque de données'
            } else {
                return r[0].lib
            }
        },
        levels: function (rank = 5) {
            let value = rank * 100 / 5
            let r = `<b-progress
      }
            type="is-danger"
            :value="${value}"
            show-value
        ></b-progress>`
            return r;
        },
        cell_color: function (note = null) {
            switch (parseInt(note)) {
                case 1:
                    return '#209c05'
                case 2:
                    return '#85e62c'
                case 3:
                    return '#ebff0a'
                case 4:
                    return '#f2ce02'
                case 5:
                    return '#ff0a0a'
                default:
                    return 'grey'
            }
        },
        cell_color_revert: function (note = null) {
            switch (parseInt(note)) {
                case 1:
                    return '#ff0a0a'
                case 2:
                    return '#f2ce02'
                case 3:
                    return '#ebff0a'
                case 4:
                    return '#85e62c'
                case 5:
                    return '#209c05'
                default:
                    return 'grey'
            }
        }
    },
    data() {
        return {
            isOpen: 0,
            ficheIsOpen: false,
            isCardModalActive: false,
            INPN: {
                description: {},
                media: {},
                fiche: ''
            },
            precoTaxon: {},
        }
    }
}
</script>

<style>

.dot {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;
}

/* Dotted border */
hr.dotted {
    border-top: 3px dotted #bbb;
}

.tooltip-trigger {
    cursor: help;
}

/*
.tooltip-trigger:before {
    display: block;
    background: #0b48b4;
    color: #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-size: 20px;
    content: "?";
    float: left;
    margin: 0 6px 0 0;
}
*/

.synthese th, td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: 3px;
}

th, td {
    padding: 3px;
}


</style>
