<template>

    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">Commentaires</p>
        </header>
        <section class="modal-card-body">

            <!-- Form -->
            <b-field grouped>
                <!-- Date -->
                <b-field label="Date">
                    <b-datepicker
                        v-model="date"
                        locale="fr-FR"
                        placeholder="Choisir la date"
                        icon="calendar-today"
                        trap-focus>
                    </b-datepicker>
                </b-field>
                <!-- Theme -->
                <b-field label="Thème">
                    <b-select
                        placeholder="Sélectionner un thème"
                        v-model="theme">
                        <option
                            v-for="theme in themes"
                            :value="theme.id"
                            :key="theme.id">
                            {{ theme.label }}
                        </option>
                    </b-select>
                </b-field>
                <!-- Auteur -->
                <b-field label="Auteur">
                    <b-input disabled v-model="auteur"></b-input>
                </b-field>
                <!-- UUID de la mozaique -->
                <b-field label="Mosaïque d'habitat">
                    <b-input disabled :value="mozUUID"></b-input>
                </b-field>

            </b-field>
            <b-field label="Commentaire">
                <b-input
                    maxlength="200"
                    type="textarea"
                    v-model="commentaire"
                >
                </b-input>
            </b-field>
            <b-field>
                <b-button
                    type="is-success"
                    @click="addComment()"
                    v-if="!isEdit">
                    Ajouter le commentaire
                </b-button>
                <b-button
                    type="is-success"
                    @click="putEditCom()"
                    v-if="isEdit">
                    Modifier le commentaire
                </b-button>
            </b-field>
            <hr>

            <!-- Table -->
            <h1 class="title">Commentaires sur la mosaïque</h1>
            <b-table
                :data="data"
                bordered
                striped
            >
                <b-table-column field="date" label="Date" v-slot="props">
                    {{ new Date(props.row.date).toLocaleDateString('fr', { weekday:"long", year:"numeric", month:"long", day:"numeric", hour:"numeric", minute:"numeric"}) }}
                </b-table-column>
                <b-table-column field="auteur" label="Auteur" v-slot="props">
                    {{ props.row.auteur }}
                </b-table-column>
                <b-table-column field="label" label="Thème" v-slot="props">
                    {{ props.row.label }}
                </b-table-column>
                <b-table-column field="commentaire" label="Commentaire" v-slot="props">
                    {{ props.row.commentaire }}
                </b-table-column>
                <b-table-column field="actions" label="Actions" v-slot="props">
                    <div class="buttons">
                        <b-button
                            v-if="auteur === props.row.auteur"
                            type="is-warning"
                            icon-left="pen"
                            @click="editCom(props.row)"
                        >
                            Editer
                        </b-button>
                        <b-button
                            v-if="auteur === props.row.auteur"
                            type="is-danger"
                            icon-left="delete"
                            @click="delCom(props.row.id_commentaires)"
                        >
                            Supprimer
                        </b-button>
                    </div>
                </b-table-column>
            </b-table>
        </section>

        <footer class="modal-card-foot">
            <b-button
                label="Fermer"
                @click="$parent.close()"/>
        </footer>
    </div>

</template>
<script>

export default {
    name: "ModalCommentaires",
    props: {
        mozUUID: String,
        themes: Array
    },
    mounted() {
        // console.log('this.mozUUID', this.mozUUID)
        this.getCommentaires();
    },
    computed: {
    },
    methods: {
        resetForm() {
            this.date = new Date();
            this.auteur = localStorage.getItem('vue-name');
            this.theme = null;
            this.commentaire = null;
        },
        delCom(id) {
            this.$axiosAuth.delete(`delCom/${id}`)
                .then(r => {
                    // console.log(r.data, id)
                    this.getCommentaires()
                })
        },
        editCom(com){
            this.isEdit = com.id_commentaires
            this.date = new Date(com.date);
            this.auteur = localStorage.getItem('vue-name'); // TODO ?
            this.theme = com.theme;
            this.commentaire = com.commentaire;
        },
        putEditCom(){
            if ( this.theme === null ||
                this.commentaire === null ||
                this.isEdit === null) {
                this.$buefy.snackbar.open({
                    message: 'Merci de vérifier votre saisie.',
                    type: 'is-danger',
                    position: 'is-bottom-left',
                    duration: 5000,
                })
            } else {
                this.$axiosAuth.put('editComment', {
                    date: this.date,
                    auteur: this.auteur,
                    theme: this.theme,
                    commentaire: this.commentaire,
                    id_commentaires: this.isEdit,
                })
                    .then(rez => {
                        // console.log(rez)
                        this.resetForm();
                        this.getCommentaires();
                        this.isEdit = null;
                    })
                    .catch(function (error) {
                        if (error.response) {
                            console.log(error.response.status);
                        }
                    });
            }
        },
        getCommentaires() {
            this.$axiosAuth.get(`getComments?UUID=${this.mozUUID}`)
                .then(rez => {
                    // console.log(rez.data)
                    this.data = rez.data
                })
                .catch(function (error) {
                    if (error.response) {
                        console.log(error.response.status);
                    }
                });
        },
        addComment() {
            if ( this.theme === null ||
                 this.commentaire === null) {
                this.$buefy.snackbar.open({
                    message: 'Merci de vérifier votre saisie.',
                    type: 'is-danger',
                    position: 'is-bottom-left',
                    duration: 5000,
                })
            } else {
                this.$axiosAuth.post('addComment', {
                    date: this.date,
                    auteur: this.auteur,
                    theme: this.theme,
                    commentaire: this.commentaire,
                    polygone: this.mozUUID,
                })
                    .then(rez => {
                        // console.log(rez)
                        this.resetForm();
                        this.getCommentaires();
                    })
                    .catch(function (error) {
                        if (error.response) {
                            console.log(error.response.status);
                        }
                    });
            }
        }
    },
    data() {
        return {
            isEdit: null,
            date: new Date(),
            auteur: localStorage.getItem('vue-name'),
            theme: null,
            commentaire: null,
            data: [],
            columns: [
                {
                    field: 'date',
                    label: 'Date',
                    centered: true
                },
                {
                    field: 'auteur',
                    label: 'Auteur',
                },
                {
                    field: 'theme',
                    label: 'Thème',
                },
                {
                    field: 'commentaire',
                    label: 'Commentaire',
                }
            ]
        }
    }
}
</script>

<style>

</style>
