import Vue from 'vue'
import App from './App.vue'

// Buefy
import Buefy from 'buefy';
import 'buefy/dist/buefy.css';

Vue.use(Buefy);

const axiosConfig = {
    baseURL: process.env.VUE_APP_BASEURL,
    timeout: 30000,  // Set timeout
};
import axios from 'axios'
import Keycloak from "keycloak-js";

Vue.prototype.$axios = axios.create(axiosConfig);

// Set a specific Axios instance when we need to send a bearer
Vue.prototype.$axiosAuth = axios.create(axiosConfig);
// For each request made with $axiosAuth, read de localstorage et send the bearer
Vue.prototype.$axiosAuth.interceptors.request.use(
    (config) => {
        const t = localStorage.getItem("vue-token");
        if (t) {
            config.headers.Authorization = `Bearer ${t}`;
        }
        return config;
    },
    (error) => {
        // console.log("request error", error);
        return Promise.reject(error);
    }
);
// Handle 403 error, when bearer is bad (exp time, not well signed...), clear the localstorage !
Vue.prototype.$axiosAuth.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response.status === 403) {
            localStorage.clear();
            window.location.reload()
        }
        // reject with error if response status is not 403
        return Promise.reject(error);
    }
);

Vue.config.productionTip = false

/*
 * KEYCLOAK
 */

const keycloak = new Keycloak({
    url: 'https://auth.cen-nouvelle-aquitaine.com/',
    realm: 'RELIOM',
    clientId: 'reliom_app',
});

keycloak
    .init({
        onLoad: "check-sso",
        redirectUri: process.env.VUE_APP_REDIRECT
    })
    .then( async (authenticated) => {
        console.log('auth', authenticated)
        if (authenticated) {
            // Store token
            localStorage.setItem("vue-token", keycloak.token);
            keycloak.loadUserInfo().then((userInfo) => {
                // Store Name
                localStorage.setItem("vue-name", userInfo.name);
            });
        } else {
            // If session is destroyed in KC, then remove localstorage
            localStorage.removeItem("vue-token");
            localStorage.removeItem("vue-name");
        }
    });

Vue.mixin({
    methods: {
        login() {
            keycloak.login();
        },
        logout() {
            keycloak.logout({});
            localStorage.removeItem("vue-name");
            localStorage.removeItem("vue-token");
        },
        whoiam() {
            const t = localStorage.getItem("vue-token");
            const r = t ? true : false;
            return {
                name: localStorage.getItem("vue-name"),
                token: localStorage.getItem("vue-token"),
                auth: r
            }
        },
        contenanceToSurface(contenance) { // All credit to https://github.com/etalab/cadastre.data.gouv.fr
            let unit = ''
            let surface = 0
            const contenanceLength = (contenance.toString().length)

            if (isNaN(contenance) || contenance < 0) {
                unit = ''
                surface = ''
            } else if (contenanceLength < 3) {
                unit = ' m²'
                surface = contenance
            } else if (contenanceLength >= 3 && contenanceLength < 5) {
                unit = ' a'
                surface = (contenance / 100).toFixed(2)
                surface = surface.replace('.', ',')
            } else {
                unit = ' ha'
                surface = (contenance / 10000).toFixed(2)
                surface = surface.replace('.', ',')
            }
            return `${surface}${unit}`
        },
    }
})

new Vue({
    render: h => h(App),
}).$mount('#app')
