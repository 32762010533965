<template>
    <section>
        <div class="container">

            <div v-if="dataFromDB.dataFromDB === null || !dataFromDB.dataFromDB.length > 0">
                <p>Sélectionner un zone à l'aide des outils polygone ou rectange sités à droite de la carte.</p>
                <p>Les informations relatives à la zone sélectionnée s'affichent</p>
            </div>

            <div v-if="dataFromDB.dataFromDB !== null && dataFromDB.dataFromDB.length > 0">

                <!-- Fiche sélection de l'utilisateur -->

                <strong>Habitats naturels présents sur le périmètre sélectionné</strong>
                <div class="card">

                    <div class="card-content">
                        <div class="content">

                            <b>Surface sélectionnée</b> : {{ contenanceToSurface(dataFromDB.selection.area) }}
                            <br>
                            <b>Commune</b> : {{ dataFromDB.selection.commune }}
                            <!--
                            <br>
                            <br>
                            <b-collapse class="card" animation="slide" aria-id="contentIdForA11y3">
                              <template #trigger="props">
                                <div
                                    class="card-header"
                                    role="button"
                                    aria-controls="contentIdForA11y3">
                                  <p class="card-header-title">
                                    Affichage des couches
                                  </p>
                                  <a class="card-header-icon">
                                    <b-icon
                                        :icon="props.open ? 'menu-down' : 'menu-up'">
                                    </b-icon>
                                  </a>
                                </div>
                              </template>

                              <div class="card-content">
                                <div class="content">

                                  <b-checkbox v-model="checkboxGroup"
                                              native-value="habitats">
                                    Habitats
                                  </b-checkbox>
                                  <br>
                                  <b-checkbox v-model="checkboxGroup"
                                              native-value="sols">
                                    Sols
                                  </b-checkbox>
                                  <br>
                                  <b-checkbox v-model="checkboxGroup"
                                              native-value="misc">
                                    Misc
                                  </b-checkbox>
                                  <br>

                                  <b>Selection:</b>
                                  {{ checkboxGroup }}

                                </div>
                              </div>
                            </b-collapse>

                            -->

                            <div v-if="clickedPolygon.color !== undefined">
                                <br>
                                <hr class="dotted">
                                <h4>Mosaïque sélectionnée</h4>
                                <div
                                    class="colorcard"
                                    v-bind:style="{ 'border-color': clickedPolygon.color}"
                                    :class="highlight === clickedPolygon.color.slice(1) ? 'back' : ''"
                                >
                                    <span
                                        v-bind:style="{ 'color': clickedPolygon.color}"
                                        class="mozaique">
                                        <b>{{
                                                contenanceToSurface(clickedPolygon.surface_relative)
                                            }} sur la zone sélectionnée</b>
                                    </span>
                                    <span class="gotodetails">
                                        <div class="buttons">
                                        <b-button
                                            outlined
                                            size="is-small"
                                            type="is-primary"
                                            @click="displayAnalyze(clickedPolygon.color)">
                                            Détails
                                        </b-button>
                                         <b-button
                                             outlined
                                             size="is-small"
                                             type="is-info"
                                             v-if="whoiam().auth"
                                             @click="displayCommentaires(clickedPolygon)">
                                                    Commentaires
                                                </b-button>
                                            </div>

                                    </span>
                                    <span v-for="item in clickedPolygon.habitats" :key="item.gid + Math.random()">

                                        <!--
                                        <b-tooltip
                                            :label="item.nom_statut"
                                        >
                                                <b-rate
                                                    :value="rateHab(item.nom_statut)"
                                                    icon-pack="mdi"
                                                    icon="star"
                                                    :max="1"
                                                    size="default"
                                                    disabled>
                                                </b-rate>
                                            </b-tooltip>
                                        -->
                                        <span>- </span>
                                        <span
                                            :class="rateHab(item.nom_statut)"
                                            v-html="item.legende"
                                        ></span>

                                        <em> ({{ item.surf_prcen }} %)</em>
                                            <br>
                                        </span>
                                </div>
                            </div>
                            <br>
                            <hr class="dotted">
                            <h4>Toutes les mosaïques présentes</h4>
                            <br>
                            <!-- CARD -->
                            <span
                                v-for="items in dataFromDB.dataFromDB"
                                :key="items.color">
                                    <div
                                        class="colorcard"
                                        v-bind:style="{ 'border-color': items.color}"
                                        :class="highlight === items.color.slice(1) ? 'back' : ''"
                                    >
                                    <span
                                        v-bind:style="{ 'color': items.color}"
                                        class="mozaique">
                                        <b>{{
                                                contenanceToSurface(items.surface_relative)
                                            }} sur la zone sélectionnée</b>
                                    </span>

                                        <span class="gotodetails">

                                             <div class="buttons">
                                                <b-button
                                                    outlined
                                                    size="is-small"
                                                    type="is-primary"
                                                    @click="displayAnalyze(items.color)">
                                                    Détails
                                                </b-button>
                                                    <b-button
                                                        outlined
                                                        size="is-small"
                                                        type="is-info"
                                                        v-if="whoiam().auth"
                                                        @click="displayCommentaires(items)">
                                                    Commentaires
                                                </b-button>
                                             </div>
                                    </span>
                                        <span v-for="item in items.habitats" :key="item.gid + Math.random()">
                                            <!--
                                            <b-button size="is-small"
                                                      icon-left="edit"
                                                      type="is-info"
                                                      @click="displayCommentaires(items)">
                                            </b-button>
                                            -->
                                            <!-- Rate intérêt communautaire -->

                                            <!--
                                            <b-tooltip
                                                :label="item.nom_statut"
                                            >
                                                <b-rate
                                                    :value="rateHab(item.nom_statut)"
                                                    icon-pack="mdi"
                                                    icon="star"
                                                    :max="1"
                                                    size="default"
                                                    disabled>
                                                </b-rate>
                                            </b-tooltip>
                                            -->

                                        <span>- </span>
                                        <span
                                            :class="rateHab(item.nom_statut)"
                                            v-html="item.legende"
                                        ></span>
                                            <em> ({{ item.surf_prcen }} %)</em>
                                            <br>
                                        </span>
                                    </div>
                                    <br>
                                </span>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
</template>

<script>


export default {
    name: "CartoucheGauche",
    props: {
        dataFromDB: {},
        highlight: {
            type: String,
            default: null
        },
    },
    mounted() {
        // this.wms()
    },
    watch: {
        // Open accordion on clic
        highlight() {
            // console.log('Prop changed: ', newVal, ' | was: ', oldVal)
            // Reorder to put selected feature on top
            let h = this.dataFromDB.dataFromDB.find(item => item.color === '#' + this.highlight);
            // console.log(h)
            this.clickedPolygon = h;
        },
    },
    computed: {},
    data() {
        return {
            clickedPolygon: {},
            checkboxGroup: [],
            layers: {
                sols: false,
                habitats: true
            },
        };
    },
    methods: {
        rateHab(status) {
            let r = ""
            switch (status) {
                case "Intérêt communautaire":
                    // r = 0.5;
                    r = "com"
                    break;
                case "Intérêt communautaire prioritaire":
                    // r = 1;
                    r = "comprio"
                    break;
                case "Non communautaire":
                    // r = 0;
                    r = ""
                    break;
                default:
                    // r = 0;
                    r = "";
            }
            return r;
        },
        displayAnalyze(e) {
            // console.log(e)
            this.$emit('fullReport', e)
        },
        displayCommentaires(e) {
            this.$emit('ViewAddCommentaires', e)
        },
        updateMap() {
            this.$emit('displayLayers', this.layers)
        }
        /* CORS à régler
        wms() {
          this.$axios.get('https://opendata.cen-nouvelle-aquitaine.org/geoserver/partage/wfs?service=wfs&version=1.1.0&request=GetCapabilities')
          .then( rez => {
            console.log(rez.data)
          })
        }
        */
    }
}
</script>

<style scoped>

.com {
    font-weight: bold;
}

.priocom {
    font-weight: bold;
}
.priocom::before {
    content: "* ";
}

.dot {
    margin-right: 20px;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;
}

.container {
    max-width: 100%;
    padding-top: 2%;
    padding-left: 5%;
    padding-right: 5%;
}

/* Dotted border */
hr.dotted {
    border-top: 3px dotted #bbb;
}

.back {
    border: solid;
    border-width: 6px;
    padding: 10px;
}

.colorcard {
    border-left: solid;
    border-left-width: 15px;
    padding: 10px;
}

.colorcard .mozaique {
    position: absolute;
    margin-top: -30px;
    margin-left: 5%;
    background-color: white;
    padding: 5px;
}

.colorcard .gotodetails {
    position: absolute;
    margin-top: -33px;
    margin-left: 50%;
    background-color: white;
    padding: 5px;
}

.rate {
    display: inline;
}

</style>
