<template>
    <div v-bind:class="{ grayscale : isGrayscale }">
        <div id="mainmap">
            <div>
                <b-button
                    icon-left="trash-can"
                    label="Réinitialiser"
                    size="is-small"
                    type="is-danger"
                    style="z-index: 1001; margin-left: 60px; margin-top: 10px"
                    @click="clearMap">
                </b-button>
                <b-button
                    icon-left="pen"
                    label="Passer en niveau de gris"
                    size="is-small"
                    type="is-info"
                    style="z-index: 1001; margin-left: 10px; margin-top: 10px"
                    @click="setGrayScale()">
                </b-button>
                <!--
                <button class="button is-info is-small" @click="backToFront">Sélection au premier plan</button>
                <button class="button is-success is-small" @click="PDFproduction">Télécharger la fiche détaillée</button>
                <button class="button is-info is-small" @click="fullReport">Afficher en version web</button>
                -->
            </div>
        </div>
    </div>
</template>

<script>
import L from 'leaflet';
import 'leaflet-draw';

export default {
    name: "MainMap",
    props: {
        layersToDisplay: {},
        mapLayers: {},
    },
    watch: {
        'mapLayers.commentaires': function (n) {
            if (n.length > 0) {
                this.loadComments()
            }
        },
        'mapLayers.sig_convention': function (n, o) {
            //
            let diff = n.filter(x => !o.includes(x));
            console.log(diff)
            if (diff.length > 0) {
                this.loadSigConvention(diff)
            } else {
                let remove = o.filter(x => !n.includes(x));
                // console.log(remove)
                this.sigconv.eachLayer(layer => {
                    // console.log(layer.options.id, remove)
                    if (layer.options.id === remove[0]) {
                        this.sigconv.removeLayer(layer);
                    }
                })

            }
        },
        'mapLayers.obstecoul': function (n) {
            if (n) {
                this.loadObst()
            } else {
                this.map.removeLayer(this.obst);
            }
        },
        'mapLayers.placettes': function (n) {
            if (n) {
                this.loadPlacettes()
            } else {
                this.map.removeLayer(this.placettes);
            }
        },
        'mapLayers.placettes_year': function () {
            if (this.mapLayers.placettes) {
                this.map.removeLayer(this.placettes);
                this.placettes.clearLayers();
                this.loadPlacettes();
            }
        },
        'mapLayers.ecobuage': function (n) {
            if (n) {
                this.loadEcobuages()
            } else {
                this.map.removeLayer(this.ecobuages);
            }
        },
        'mapLayers.ecobuage_year': function () {
            if (this.mapLayers.ecobuage) {
                this.map.removeLayer(this.ecobuages);
                this.ecobuages.clearLayers();
                this.loadEcobuages();
            }
        },

        'layersToDisplay.habitats': function (newVal, oldVal) {
            console.log('Prop changed: ', newVal, ' | was: ', oldVal)
            // On each change for layer var
            if (this.layersToDisplay.habitats === true) {
                this.map.addLayer(this.habitats);
            } else {
                this.map.removeLayer(this.habitats);
            }
        },
        'layersToDisplay.sols': function (newVal, oldVal) {
            console.log('Prop changed: ', newVal, ' | was: ', oldVal)
            // On each change for layer var
            if (this.layersToDisplay.sols === true) {
                this.map.addLayer(this.sols);
            } else {
                this.map.removeLayer(this.sols);
            }

        },
    },
    mounted() {
        this.initMap();
    },
    methods: {
        setGrayScale() {
            this.isGrayscale = !this.isGrayscale
        },
        getStyle(layer) {
            let hash = 0;
            for (let i = 0; i < layer.length; i++) {
                hash = layer.charCodeAt(i) + ((hash << 5) - hash);
            }
            let colour = '#';
            for (let i = 0; i < 3; i++) {
                let value = (hash >> (i * 8)) & 0xFF;
                colour += ('00' + value.toString(16)).substr(-2);
            }
            return colour;
            /*
            switch (layer) {
                case 'Oranges':
                case 'cayolars':
                case 'chargement_2019':
                case 'cloturescsvb':
                case 'desserte_pistes_up':
                case 'equipements_pastoraux_n2k':
                case 'placettes_equarissage':
                case 'plantations':
                case 'sites_apiculteurs':
                case 'syndical':
                case 'up_agrege':
                default:
                    console.log(`Sorry, we are out of.`);
            }
             */
        },
        loadSigConvention(list) {
            this.$axiosAuth.get(`loadSigConvention?layers=${list}`)
                .then(function (response) {
                    response.data.forEach(p => {
                        for (let g of p.data) {
                            // Popup
                            // Dynamic content with database table columns
                            let popupContent = '';
                            for (const property in g) {
                                popupContent += property !== 'geom' ? `<b>${property}: </b> ${g[property]} <br>` : '';
                            }
                            // Generate a color with the layer name
                            const genColor = this.getStyle(p.layer);

                            let i = L.geoJSON(JSON.parse(g.geom), {
                                // Pick a color based on name
                                color: genColor,
                                // Set a custom id field to remove features
                                id: p.layer,
                                // For markers, set a color circle icon
                                style: function () {
                                    return {
                                        color: genColor,
                                    };
                                },
                                pointToLayer: function (feature, latlng) {
                                    return new L.CircleMarker(latlng, {
                                        radius: 10,
                                        fillOpacity: 0.85
                                    });
                                },
                                // Bind popup
                                onEachFeature: function Popup(feature, layer) {
                                    layer.bindPopup(popupContent);
                                },
                            });
                            this.sigconv.addLayer(i)
                        }
                    })
                    this.map.addLayer(this.sigconv);
                }.bind(this))
                .catch(function (error) {
                    if (error.response) {
                        console.log(error.response.status);
                    }
                }.bind(this));
        },
        loadObst() {
            this.$axios.get(`obstecoul`)
                .then(function (response) {
                    response.data.forEach(p => {
                        // Popup
                        function ObstPopup(feature, layer) {
                            layer.bindPopup(p.annee + '<br>' + p.dn + '<br>' + p.surface);
                        }

                        let i = L.geoJSON(JSON.parse(p.geom), {
                            onEachFeature: ObstPopup,
                        });
                        this.obst.addLayer(i)
                    })
                    this.map.addLayer(this.obst);
                }.bind(this))
        },
        loadEcobuages() {
            this.$axios.get(`ecobuage?year=${this.mapLayers.ecobuage_year}`)
                .then(function (response) {
                    response.data.forEach(p => {
                        // Popup
                        function EcobuagePopup(feature, layer) {
                            layer.bindPopup(p.annee + '<br>' + p.dn + '<br>' + p.surface);
                        }

                        // Couche écobuage
                        let i = L.geoJSON(JSON.parse(p.geom), {
                            onEachFeature: EcobuagePopup,
                        });
                        this.ecobuages.addLayer(i)
                    })
                    this.map.addLayer(this.ecobuages);
                }.bind(this))
        },
        loadPlacettes() {
            this.$axios.get(`placettes-suivi?year=${this.mapLayers.placettes_year}`)
                .then(function (response) {
                    // console.log(response.data)
                    // Loop each features for sols
                    response.data.forEach(p => {
                        // Popup
                        function PlacettesPopup(feature, layer) {
                            layer.bindPopup(
                                `${p.annee} <br>
                                ${p.nom_carto} <br>
                                ${p.nom} <br>
                                <a href="files/${p.nom}.pdf" target="_blank">PDF</a>`);
                        }

                        // Points placettes
                        let i = L.geoJSON(JSON.parse(p.geom), {
                            onEachFeature: PlacettesPopup,
                        });
                        this.placettes.addLayer(i)
                    })
                    this.map.addLayer(this.placettes);
                }.bind(this))
        },
        test(e) {
            console.log('test', e)
        },
        loadComments() {
            console.log(this.mapLayers.commentaires)
            this.$axiosAuth.get(`getAllComments?themes=${this.mapLayers.commentaires}`)
                .then(function (r) {
                    r.data.forEach(p => {
                        // Popup
                        function ComIcon (feature, latlng) {
                            let myIcon = L.icon({
                                iconUrl: require('@/assets/path1002.png'),
                                // shadowUrl: 'my-icon.png',
                                iconSize:     [50, 50], // width and height of the image in pixels
                                // shadowSize:   [35, 20], // width, height of optional shadow image
                                iconAnchor:   [25, 25], // point of the icon which will correspond to marker's location
                                // shadowAnchor: [12, 6],  // anchor point of the shadow. should be offset
                                popupAnchor:  [0, 0] // point from which the popup should open relative to the iconAnchor
                            })
                            function mark_click(e){
                                console.log(e.target.options);
                                this.test(e) // TODO remove here
                            }
                            return L.marker(latlng, {
                                icon: myIcon,
                                id: p.polygone
                            }).on('click', mark_click);
                        }
                        function Popup(feature, layer) {
                            layer.bindPopup(p.count + ' commentaire(s)<br>' + `<a class="smallPolygonLink" href="#">Click here to see the smaller polygons</a>`);
                        }
                        let i = L.geoJSON(JSON.parse(p.geom), {
                            polygon: p.polygone,
                            onEachFeature: Popup,
                            pointToLayer: ComIcon
                        });
                        this.commentairesLayer.addLayer(i)
                    })
                    this.map.addLayer(this.commentairesLayer);
                }.bind(this))
                .catch(function (error) {
                    if (error.response) {
                        console.log(error.response.status);
                    }
                });
        },
        PDFproduction() {
            let mapSize = {
                width: this.map.getSize().x,
                height: this.map.getSize().y
            }
            // console.log(mapSize)
            this.$emit('PDFproduction', mapSize)
        },
        clearMap() {
            this.drawnItems.eachLayer(layer => {
                this.map.removeLayer(layer);
            });
            this.habitats.eachLayer(layer => {
                this.map.removeLayer(layer);
            });
            this.sols.eachLayer(layer => {
                this.map.removeLayer(layer);
            });
            this.selection = {};
            this.$emit('clearCard')
        },
        fullReport() {
            this.$emit('fullReport')
        },
        backToFront() {
            this.drawnItems.bringToFront();
        },
        initMap() {
            // Map declaration
            this.map = L.map('mainmap',
                {}).setView([45.05, 0.6167], 8);

            // Base layers
            let osm = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                maxNativeZoom: 19, // OSM max available zoom is at 19
                maxZoom: 22, // Match the map maxZoom, or leave map.options.maxZoom undefined.
                attribution: '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                zIndex: 1,
            });

            let tonerlite = L.tileLayer('https://stamen-tiles.a.ssl.fastly.net/toner/{z}/{x}/{y}.png', {
                maxNativeZoom: 19, // OSM max available zoom is at 19
                maxZoom: 22, // Match the map maxZoom, or leave map.options.maxZoom undefined.
                attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, under <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>. Data by <a href="http://openstreetmap.org">OpenStreetMap</a>, under <a href="http://www.openstreetmap.org/copyright">ODbL</a>.',
                zIndex: 1,
            });

            const m3 = L.tileLayer("https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png",
                {
                    maxNativeZoom: 19, // OSM max available zoom is at 19
                    maxZoom: 22, // Match the map maxZoom, or leave map.options.maxZoom undefined.
                    attribution: '&copy; Openstreetmap France | &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> - Données et intégration &copy; CEN Nouvelle-Aquitaine',
                    zIndex: 1,
                });
            const m4 = L.tileLayer("https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png",
                {
                    maxNativeZoom: 15, // OSM max available zoom is at 19
                    maxZoom: 22, // Match the map maxZoom, or leave map.options.maxZoom undefined.
                    attribution: 'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="http://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a> - Données et intégration &copy; CEN Nouvelle-Aquitaine)',
                    zIndex: 1,
                });


            // Hard layers definition :)
            this.drawnItems = new L.FeatureGroup();
            this.map.addLayer(this.drawnItems);
            this.habitats = new L.FeatureGroup();
            this.map.addLayer(this.habitats);
            this.sols = new L.FeatureGroup();
            this.placettes = new L.FeatureGroup();
            this.ecobuages = new L.FeatureGroup();
            this.obst = new L.FeatureGroup();
            this.sigconv = new L.FeatureGroup();
            this.commentairesLayer = new L.FeatureGroup();

            // Overlays

            let wmsLayer = L.tileLayer.wms('https://opendata.cen-nouvelle-aquitaine.org/administratif/wms?',
                {
                    layers: 'foncier_agg',
                    format: 'image/png',
                    transparent: true,
                    maxZoom: 22
                });

            const overlays = {
                "Limites administratives": wmsLayer
            }

            // Add default basemap
            osm.addTo(this.map);

            // Add scalebar
            L.control.scale().addTo(this.map);

            // Add controls
            L.control.layers(
                {
                    'OSM': osm,
                    "OSM FR": m3,
                    'Niveau de gris': tonerlite,
                    "OSM Topo": m4,
                },
                overlays
            )
                .addTo(this.map);

            // Get layers
            this.$axios.get(`emprise`)
                .then(function (response) {
                    let emprise = L.geoJSON(JSON.parse(response.data[0].geom), {
                        color: '#d55757',
                        // fillColor: '#7957d5',
                        weight: 2,
                        fillOpacity: 0,
                        // myCustomId: "hello",
                    });
                    emprise.addTo(this.map)
                    this.map.fitBounds(emprise.getBounds());
                }.bind(this))
                .catch(error => console.log(error));

            // Drawing controls
            let drawControl = new L.Control.Draw({
                position: 'topleft',
                draw: {
                    polygon: {
                        shapeOptions: {
                            color: 'purple'
                        },
                        allowIntersection: false,
                        drawError: {
                            color: 'orange',
                            timeout: 1000
                        },
                        showArea: true,
                        metric: false,
                        repeatMode: false
                    },
                    polyline: false,
                    rect: {
                        shapeOptions: {
                            color: 'purple'
                        },
                    },
                    circle: false,
                    circlemarker: false,
                    marker: false,
                },
                edit: false
            });

            // Translation, see https://github.com/Leaflet/Leaflet.draw/blob/master/src/Leaflet.draw.js
            L.drawLocal = {
                draw: {
                    toolbar: {

                        //
                        actions: {
                            title: 'Annuler le dessin',
                            text: 'Annuler'
                        },
                        finish: {
                            title: 'Finir le dessin',
                            text: 'Finir'
                        },
                        undo: {
                            title: 'Supprimer le dernier point dessiné',
                            text: 'Supprimer le dernier point'
                        },
                        buttons: {
                            polyline: 'Dessiner une polyligne',
                            polygon: 'Dessiner un polygone',
                            rectangle: 'Dessiner un rectangle',
                            circle: 'Dessiner un cercle',
                            marker: 'Dessiner un point',
                            circlemarker: 'Dessiner un marqueur cercle'
                        }
                    },
                    handlers: {
                        circle: {
                            tooltip: {
                                start: 'Cliquer et glisser pour dessiner un cercle'
                            },
                            radius: 'Radius'
                        },
                        circlemarker: {
                            tooltip: {
                                start: 'Click map to place circle marker.'
                            }
                        },
                        marker: {
                            tooltip: {
                                start: 'Click map to place marker.'
                            }
                        },
                        polygon: {
                            tooltip: {
                                start: 'Cliquer pour commencer votre polygone',
                                cont: 'Cliquer pour continuer le dessin',
                                end: 'Cliquer sur votre premier point pour fermer le polygone'
                            }
                        },
                        polyline: {
                            error: '<strong>Erreur:</strong> les traits se croisent !',
                            tooltip: {
                                start: 'Click to start drawing line.',
                                cont: 'Click to continue drawing line.',
                                end: 'Click last point to finish line.'
                            }
                        },
                        rectangle: {
                            tooltip: {
                                start: 'Cliquer et glisser pour dessiner un rectangle'
                            }
                        },
                        simpleshape: {
                            tooltip: {
                                end: 'Relacher le clic pour finir le dessin'
                            }
                        }
                    }
                },
                edit: {
                    toolbar: {
                        actions: {
                            save: {
                                title: 'Sauvegarder les changements',
                                text: 'Sauvegarder'
                            },
                            cancel: {
                                title: 'Cancel editing, discards all changes',
                                text: 'Cancel'
                            },
                            clearAll: {
                                title: 'Clear all layers',
                                text: 'Clear All'
                            }
                        },
                        buttons: {
                            edit: 'Edit layers',
                            editDisabled: 'No layers to edit',
                            remove: 'Delete layers',
                            removeDisabled: 'No layers to delete'
                        }
                    },
                    handlers: {
                        edit: {
                            tooltip: {
                                text: 'Drag handles or markers to edit features.',
                                subtext: 'Click cancel to undo changes.'
                            }
                        },
                        remove: {
                            tooltip: {
                                text: 'Click on a feature to remove.'
                            }
                        }
                    }
                }
            };

            this.map.addControl(drawControl);

            // Events
            // Trigger function when LayerGroup is checked
            this.map.on({
                overlayadd: function (e) {
                    console.log(e.name);
                },
                overlayremove: function (e) {
                    console.log(e.name);
                }
            });

            this.map.on('draw:created', e => {

                // Zoom on new layer
                this.map.fitBounds(e.layer.getBounds(), {padding: [2, 2]});

                let geom = e.layer._latlngs[0];
                let bounds = e.layer._bounds;

                // Watchdog, check if surface is not too big to compute
                let type = e.layerType,
                    layer2 = e.layer;
                let polygons = new L.featureGroup();
                if (type === 'polygon' || type === 'rectangle') {
                    polygons.addLayer(layer2);
                    let seeArea = L.GeometryUtil.geodesicArea(layer2.getLatLngs()[0]);
                    console.log(seeArea / 10000);
                    if (seeArea / 10000 > 50) {
                        this.$buefy.dialog.alert({
                            title: 'Sélection trop importante',
                            message: 'Vous avez sélectionné une zone trop grande, merci d\'affiner votre rechercher géographique afin de pouvoir charger les résultats.',
                            type: 'is-danger',
                            ariaRole: 'alertdialog',
                            ariaModal: true
                        })
                        return null;
                    }
                }

                this.$axios.post('queryPolygon', {
                    selectedGeom: geom,
                    selectedBounds: bounds
                })
                    .then(rez => {

                        // General info
                        this.selection = rez.data.selection;

                        let mozaics = [];
                        rez.data.habitats.forEach(h => {
                            // Produce unique polygon if multi-habitat
                            if (!mozaics.find(i => i.geom === h.geom)) {
                                mozaics.push({
                                    geom: h.geom,
                                    // TODO, sometimes colors are malformed
                                    color: '#'+(Math.random()*0xFFFFFF<<0).toString(16),
                                    habitats: [h],
                                    surface_relative: h.surface_relative
                                });
                            } else {
                                let index = mozaics.findIndex(i => i.geom === h.geom);
                                mozaics[index].habitats.push(h);
                            }
                        })

                        // Add habitats mozaics to map
                        mozaics.forEach(m => {

                            // Popup on mozaïc polygon
                            // let content = `${ m.surface_relative } <b>Liste des habitats</b><br>`
                            let content = `<b>Liste des habitats</b><br>`
                            m.habitats.forEach(i => {
                                content += `- ${i.legende} (${i.code_eunis})<br>`
                            })

                            function onEachMarketFeature(feature, layer) {
                                layer.bindPopup(content);
                            }

                            let habitat = L.geoJSON(JSON.parse(m.geom), {
                                onEachFeature: onEachMarketFeature,
                                color: m.color,
                                fillColor: m.color,
                                weight: 2,
                                fillOpacity: 0.4,
                                myCustomId: m.color.slice(1),
                            });
                            this.habitats.addLayer(habitat)
                            habitat.on('click', e => {
                                console.log(e.target.options.myCustomId)
                                this.$emit('highlightSelectMozaic', e.target.options.myCustomId)
                            })
                        })


                        // Loop each features for sols
                        rez.data.sols.forEach(s => {
                            // Popup
                            function SolsPopup(feature, layer) {
                                layer.bindPopup(s.nom_ucs);
                            }

                            // Sols
                            let sols = L.geoJSON(JSON.parse(s.geom), {
                                // color: h.color,
                                // fillColor: h.color,
                                onEachFeature: SolsPopup,
                                weight: 2,
                                fillOpacity: 0.4,
                                myCustomId: "hello",
                            });
                            this.sols.addLayer(sols)
                            this.sols.bringToBack();
                        })

                        let send = {
                            observations: rez.data.obs,
                            dataFromDB: mozaics,
                            habitats_pref: rez.data.liste_taxons,
                            selection: this.selection,
                        }
                        // console.log(send)
                        this.$emit('loadCard', send)
                    })

                // Add draw geom to map
                let layer = e.layer;
                this.drawnItems.addLayer(layer);

            });
        },
    },
    data() {
        return {
            isGrayscale: false,
            // Layers
            drawnItems: {},
            habitats: {},
            sols: {},
            placettes: {},
            ecobuages: {},
            obst: {},
            sigconv: {},
            commentairesLayer: {},
            // General
            selection: {}
        }
    }
}
</script>

<style scoped>

#mainmap {
    width: 100%;
    height: calc(100vh - 2px);
    z-index: 1;
}

img.huechange {
    filter: hue-rotate(120deg);
}
</style>

<style>

div.grayscale .leaflet-tile {
    filter: grayscale(100%);
}

</style>

